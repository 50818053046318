import { Component } from '@angular/core'
import { SearchService } from '@core/services/search.service'
import { InputTextModule } from 'primeng/inputtext'
import { PaginatorModule } from 'primeng/paginator'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ButtonModule } from 'primeng/button'

@Component({
  selector: 'tb-search',
  standalone: true,
  templateUrl: 'searchbar.component.html',
  styleUrl: './searchbar.component.scss',
  imports: [
    InputTextModule,
    PaginatorModule,
    InputGroupModule,
    InputGroupAddonModule,
    ButtonModule
  ]
})
export class SearchbarComponent {

  protected inputValue: string = ''
  protected inputChanged: Subject<string> = new Subject<string>()

  constructor(protected searchService: SearchService) {
    /*this.inputChanged.pipe(
      filter(Boolean), // make sure that we don't pass undefined values
      debounceTime(600),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchService.search(value)
      })*/

    this.searchService.subject$.subscribe(val => this.inputValue = val)
  }

  protected doSearch(): void {
    this.searchService.search(this.inputValue.trim())
  }

  protected doGlobalSearch(): void {
    this.searchService.search(this.inputValue, true)
  }

}
