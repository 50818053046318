<p-inputGroup>
  <input
    pInputText
    type="text"
    placeholder="Suche"
    class="w-full w-30rem"
    [(ngModel)]="inputValue"
    (ngModelChange)="this.inputChanged.next($event)"
    (keydown.enter)="doSearch()"
    (keydown.shift.enter)="doGlobalSearch()"
  />
  <button type="button" pButton icon="pi pi-search" class="p-button-warning" (click)="doSearch()"></button>
</p-inputGroup>
